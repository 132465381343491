<template>
  <div>
    <div class="top-wrap" style="position: absolute; top: 1vh;width: 100%;z-index: 999">
      <div class="container">
        <div class="row" style="margin-top: 30px;">
          <div class="col">
            <img alt="Vue logo" src="../assets/logo.png">
          </div>
        </div>
      </div>
    </div>
    <div class="main-wrapper" style="position: relative; top: -6vh;">
      <div class="container main-sentence justify-content-center text-center">

        <span id="sentence" style="font-size: 2rem;">{{ msgString }}</span>

      </div>
    </div>
    <div class="foot-1" style="position: absolute; bottom: 7vh;width: 100%;">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <!--                <p class="lead text">截屏分享朋友</p>-->
            <span class="btn btn-primary btn-filled btn-xs">
                    <a class="btn btn-primary btn-filled btn-xs" href="https://www.wuduanyun.com">www.wuduanyun.com</a>
                </span>
          </div>
        </div>
      </div>
    </div>
    <div style="display:none;">

    </div>
  </div>

</template>

<script>
export default {
  name: "Main",
  data() {
    return {
      "msgString": "",
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData: function () {
      this.$axios.get("/api").then(res => {
        this.msgString = res.data.data.Title;
      })
    }
  }
}
import 'bootstrap/dist/css/bootstrap.min.css'
</script>

<style scoped>
.btn-primary.active, .btn-primary:active, .btn-primary:hover, .open .dropdown-toggle.btn-primary .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  outline: none !important;
  border: 2px solid #1aaf5d;
  background-color: #1aaf5d;
  color: #fff;
}

.btn-primary.btn-filled {
  background: #1aaf5d;
  color: #fff;
}

.btn:hover {
  border-color: #1aaf5d;
  background: none;
  background-color: #1aaf5d;
  color: #fff;
}

.btn-primary.active, .btn-primary:active, .open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary {
  display: inline-block;
  padding: 14px 28px 13px 28px;
  border: 2px solid #1aaf5d;
  border-radius: 25px;
  color: #1aaf5d;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 11px;
  font-family: "Raleway", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1;
}

.btn {
  min-width: 50px;
  background: none;
  -webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  transition: all .3s ease;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: none !important;
}

.btn-xs {
  padding: 8px 24px;
  font-size: 12px;
}

.btn.active, .btn:active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
}

.badge-primary {
  border: 1px solid #1aaf5d;
  background-color: #fff;
  color: #1aaf5d;
}

.btn-primary.disabled, .btn-primary:disabled {
  border-color: #888;
  background-color: #fff;
  color: #888;
}

.text-box {
  width: 100%;
  height: 60px;
  max-width: 100%;
  color: #333333;
  white-space: nowrap;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  word-break: keep-all;
}

.text-box:before {
  display: inline-block;
  height: 100%;
  content: '';
  vertical-align: middle;
}

#sentence {
  vertical-align: middle;
}

.main-wrapper {
  display: flex;
  height: 100vh;
  align-items: center;
}

@media (max-width: 1000px) {

  .lead {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {

  .lead {
    font-size: 0.9rem;
  }

  .btn-primary {
    padding: 8px 24px;
  }
}

footer.short {
  color: #fff;
  font-size: 16px;
}

.bg-secondary-1 {
  background-color: #2c3e50 !important;
}

footer {
  padding-top: 80px;
  padding-bottom: 80px;
}

footer.short .sub {
  opacity: 0.5;
}

footer.short ul {
  display: inline-block;
  margin: 0px;
  line-height: 24px;
}

footer.short ul li {
  float: left;
  margin-right: 32px;
}

ul {
  padding: 0px;
  list-style: none;
}

footer.short ul li a {
  color: #fff;
  opacity: 0.8;
}

footer.short {
  padding-top: 50px;
  padding-bottom: 50px;
}

.buttom-text {
  margin-top: 10px;
}

@media (max-width: 600px) {

  footer.short {
    padding-top: 50px;
    padding-bottom: 30px;
    font-size: 14px;
  }

  footer.short ul {
    padding-left: 12px;
  }

  footer.short ul li {
    margin-right: 12px;
  }
}
</style>