import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'
import axios from "axios";

Vue.config.productionTip = false
Vue.prototype.$axios = axios
axios.defaults.timeout=5000

new Vue({
    render: h => h(App),
}).$mount('#app')
